export const options = {
  loop: {
    controls: false,
    autostart: true,
    displaydescription: false,
    displaytitle: false,
    autostart: true,
    repeat: true,
    mute: true
  },
  short: {
    repeat: true,
    aspectratio: "9:16",  
    controls: true,  
    advertising: {
      autoplayadsmuted: false,
      enablePPS: false,
      outstream: false,
      preloadAds: false,
      schedule: []
    }
  },
  shortSlider: {
    repeat: true,
    aspectratio: "9:16",  
    controls: false,  
    autostart: false,
    advertising: {
      autoplayadsmuted: false,
      enablePPS: false,
      outstream: false,
      preloadAds: false,
      schedule: []
    }
  },
  multimedia: {
    advertising: {
      autoplayadsmuted: false,
      enablePPS: false,
      outstream: false,
      preloadAds: false,
      schedule: []
    }
  },
  default: {
    "advertising": {
      "admessage": "El contenido comenzara en xx segundos",
      "adscheduleid": "8cs97PiG",
      "autoplayadsmuted": true,
      "client": "googima",
      "cuetext": "Publicidad",
      "enablePPS": false,
      "endstate": "suspended",
      "outstream": false,
      "preloadAds": false,
      "rules": {
        "startOnSeek": "pre",
        "timeBetweenAds": 0
      },
      "schedule": [
        {
          "offset": "pre",
          "tag": "https://pubads.g.doubleclick.net/gampad/live/ads?iu=/242767168/clarin/player3&description_url=https%3A%2F%2Fwww.clarin.com&tfcd=0&npa=0&sz=400x300%7C640x480%7C800x600%7C1280x720&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=&vpmute=1&plcmt=1",
          "type": "linear"
        }
      ],
      "skipmessage": "Skip ad in xx seconds",
      "vpaidcontrols": true,
      "vpaidmode": "enabled"
    },
    "aspectratio": "16:9",
    "autoPause": {
      "viewability": true
    },
    "autostart": "viewable",
    "captions": {
      "backgroundColor": "#000000",
      "backgroundOpacity": 75,
      "color": "#FFFFFF",
      "edgeStyle": "none",
      "fontFamily": "sans-serif",
      "fontOpacity": 100,
      "fontSize": 15,
      "windowColor": "#000000",
      "windowOpacity": 0
    },
    "controls": true,
    "displayHeading": false,
    "displaydescription": false,
    "displaytitle": false,
    "floating": {
      "mode": "never"
    },
    "generateSEOMetadata": false,
    "height": 360,
    "include_compatibility_script": false,
    "interactive": false,
    "interactivity": false,
    "intl": {
      "en": {
        "advertising": {
          "admessage": "",
          "cuetext": "",
          "skipmessage": ""
        },
        "related": {
          "autoplaymessage": "__title__ empezar\u00e1 en xx",
          "heading": "Mir\u00e1 los videos m\u00e1s vistos"
        }
      }
    },
    "key": "5LLYtvCEow68vbx97Cwz/tygjjtYe8ff0ZF3dXegubNbQG5aGY3lWGho/Ha8tEEd",
    "localization": {
      "related": "Related Videos"
    },
    "logo": {
      "hide": false,
      "position": "top-right"
    },
    "mute": true,
    "ph": 1,
    "pid": "KZu9FPb5",
    "pipIcon": "disabled",
    "playbackRateControls": true,
    "preload": "metadata",
    "related": {
      "autoplaymessage": "__tittle__ comenzar\u00e1 en xx seg",
      "autoplaytimer": 10,
      "displayMode": "shelf",
      "file": "//cdn.jwplayer.com/v2/playlists/fBs4Jv0A?related_media_id=MEDIAID",
      "onclick": "play",
      "oncomplete": "autoplay"
    },
    "repeat": false,
    "skin": {
      "controlbar": {
        "background": "rgba(0,0,0,0)",
        "icons": "rgba(255,255,255,0.8)",
        "iconsActive": "#FFFFFF",
        "text": "#FFFFFF"
      },
      "menus": {
        "background": "#333333",
        "text": "rgba(255,255,255,0.8)",
        "textActive": "#FFFFFF"
      },
      "timeslider": {
        "progress": "#F2F2F2",
        "rail": "rgba(255,255,255,0.3)"
      },
      "tooltips": {
        "background": "#FFFFFF",
        "text": "#000000"
      }
    },
    "stretching": "uniform",
    "width": "100%"
  }
}