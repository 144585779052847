import styled from "styled-components";
import { Font, Color } from "@/shared/Constants";

const NewsReadContent = styled.p`
  display: flex;
  align-items: center;
  ${Font.sansRegular};
  font-size: 14px;
  .checkmark {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: ${Color.mainColor};
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 8px;
    &::before {
      content: "✓";
    }
  }
`;

export const NewsRead = ({ content }) => {
  return (
    <NewsReadContent>
      <span className="checkmark" />
      {content}
    </NewsReadContent>
  );
};
